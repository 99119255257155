import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import { useContext, useEffect, useId, useState } from 'react'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { SVGIcon } from '../SVGIcon'
import { mixins, theme } from '../../../styles'
import { useHeaderHeight } from '../../../hook/useHeaderHeight'
import { MENU_SECONDARY_ID } from '../../../constants/components'
import { HOME_URLS } from '../../../constants/home'

export interface IMenuNavigation {
  elementList: BlockTypes['header-navigation']['data']['navigation']
  isSticky?: boolean
}

const HEADER_PADDING = 16
const HEADER_BORDER = 5

function MenuNavigationItem({
  menu,
  index,
  isHighlight,
  onMouseEnter,
  isSubMenuOpen,
  toggleDropDown,
}: {
  menu: BlockTypes['header-navigation']['data']['navigation'][number]
  index: number
  isHighlight: boolean
  onMouseEnter: () => void
  isSubMenuOpen: boolean
  toggleDropDown: () => any
}) {
  const controlId = useId()
  const { page } = useContext(ApiResponseContext)
  const ItemComponent = menu?.elementList?.length ? 'button' : isHighlight ? 'span' : 'a'
  const headerHeight = useHeaderHeight()

  const itemProps = menu?.elementList?.length
    ? {
        onClick: toggleDropDown,
        'aria-expanded': isSubMenuOpen,
        'aria-controls': controlId,
      }
    : isHighlight
    ? {}
    : { href: menu.link }

  function renderItemContent() {
    return (
      <>
        {menu.picto ? (
          <SVGIcon
            name={menu.picto}
            primaryColor={theme.cssVars.white}
            secondaryColor={menu.color}
          />
        ) : null}
        {menu.title}
        {menu?.elementList?.length ? (
          <SVGIcon primaryColor={theme.cssVars.white} name="chevron" orientation="down" />
        ) : null}
      </>
    )
  }

  return (
    <>
      <li
        className={[
          'MenuNavigationItem flex',
          isHighlight ? 'MenuNavigationItem--highlight' : '',
        ].join(' ')}
        aria-current={isHighlight ? 'true' : undefined}
        onMouseEnter={onMouseEnter}
        id={`menu-item-${index}`}
      >
        <ItemComponent
          {...itemProps}
          className="MenuNavigationItem__Link flex items-center justify-center"
        >
          {renderItemContent()}
        </ItemComponent>
        {menu?.elementList?.length ? (
          <>
            <ul
              id={controlId}
              className={[
                'MenuNavigationItem__Dropdown flex flex-column',
                isSubMenuOpen ? 'MenuNavigationItem__Dropdown--open' : '',
              ].join(' ')}
            >
              {menu.elementList.map((submenu, index) => (
                <li key={index} aria-current={isHighlight ? 'true' : undefined}>
                  {submenu?.link === page.url ? (
                    <span>{submenu.title}</span>
                  ) : (
                    <a href={submenu?.link}>{submenu.title}</a>
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </li>
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .MenuNavigationItem--highlight {
          background-color: ${theme.colors.darkBlue};
        }

        .MenuNavigationItem {
          position: relative;
          padding: 8px;
          border-radius: 8px;
        }

        .MenuNavigationItem :global(svg) {
          min-width: 24px;
        }

        .MenuNavigationItem__Link {
          cursor: pointer;
          background: transparent;
          border: none;
          padding-top: 2px;
          gap: 6px;

          font-family: ${theme.cssVars.overpass};
          color: ${theme.colors.white};
          font-size: 11px;
          font-weight: 900;
          text-transform: uppercase;
        }

        .MenuNavigationItem__Link :global(svg) {
          margin-top: -2px;
        }

        .MenuNavigationItem__Dropdown {
          position: absolute;
          top: calc(100% + ${headerHeight / 2 - HEADER_PADDING}px);
          left: 0;
          min-width: 100%;

          list-style: none;
          font-size: 15px;
          color: ${theme.cssVars.white};
          background: ${theme.cssVars.headerBackground};
          border: 1px solid ${theme.cssVars.lightGrayBlueOpacity};
          padding: 16px;
          gap: 24px;
        }

        .MenuNavigationItem__Dropdown:not(.MenuNavigationItem__Dropdown--open) {
          display: none;
          visibility: hidden;
        }

        .MenuNavigationItem__Dropdown--open {
          animation: fadeIn 350ms;
        }

        .MenuNavigationItem__Dropdown span {
          font-weight: 800;
        }

        @media ${mixins.mediaQuery.desktop} {
          .MenuNavigationItem__Link {
            gap: 8px;
          }

          .MenuNavigationItem__Link {
            font-size: 15px;
          }
        }
      `}</style>
    </>
  )
}

export function MenuNavigation({ elementList, isSticky }: IMenuNavigation): JSX.Element {
  const { page } = useContext(ApiResponseContext)
  const currentMenuIndex = elementList.findIndex(
    (menu) => menu.link === page.url || isSubMenuCurrentPage(menu),
  )
  const [hoveredTab, setHoveredTab] = useState<number | null>(null)
  const [borderPosition, setBorderPosition] = useState(0)
  const [borderWidth, setBorderWidth] = useState(0)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState()

  const isHomePage = HOME_URLS.includes(page.url)

  const currenBorderColor =
    elementList[hoveredTab]?.color ?? elementList[currentMenuIndex]?.color ?? theme.colors.lightBlue

  function toggleDropDown(index) {
    return () => setIsSubMenuOpen(index === isSubMenuOpen ? null : index)
  }

  function isSubMenuCurrentPage(
    menu: BlockTypes['header-navigation']['data']['navigation'][number],
  ): boolean {
    return menu?.elementList?.some(({ link }) => link === page.url)
  }

  useEffect(() => {
    if (hoveredTab === null) {
      setHoveredTab(currentMenuIndex)
    }
  }, [hoveredTab])

  useEffect(() => {
    setBorderPosition(document.getElementById(`menu-item-${hoveredTab}`)?.offsetLeft ?? 0)
    setBorderWidth(document.getElementById(`menu-item-${hoveredTab}`)?.offsetWidth ?? 0)
  }, [isSticky, hoveredTab])

  if (!elementList?.length) {
    return null
  }

  return (
    <>
      <nav
        role="navigation"
        aria-label="Menu Secondaire"
        className="MenuNavigation"
        id={MENU_SECONDARY_ID}
        tabIndex={-1}
        onMouseLeave={() => setHoveredTab(currentMenuIndex)}
      >
        <div className="MenuNavigation__Border" />
        <ul className="MenuNavigation__List flex flex-no-wrap">
          {elementList.map((menu, index) => {
            const isHighlight = index === currentMenuIndex
            return (
              <MenuNavigationItem
                key={index}
                index={index}
                menu={menu}
                isHighlight={isHighlight}
                onMouseEnter={() => setHoveredTab(index)}
                toggleDropDown={toggleDropDown(index)}
                isSubMenuOpen={isSubMenuOpen === index}
              />
            )
          })}
        </ul>
      </nav>

      <style jsx>{`
        .MenuNavigation__Border {
          background-color: ${currenBorderColor};
          left: ${borderPosition}px;
          width: ${borderWidth}px;
        }
      `}</style>
      <style jsx>{`
        .MenuNavigation {
          display: none;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .MenuNavigation {
            display: block;
            height: 100%;
            width: 100%;
          }
        }

        .MenuNavigation__List {
          height: 100%;
          gap: 8px;
          padding: 0;
          margin: 0;
          list-style: none;
        }

        @keyframes fadeInBorder {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .MenuNavigation__Border {
          position: absolute;
          bottom: -${(isHomePage ? HEADER_PADDING : 0) + HEADER_BORDER}px;
          height: 5px;
          z-index: 2;
          transition: all 0.3s ease;
          animation: fadeInBorder 1s;
        }

        @media ${mixins.mediaQuery.desktop} {
          gap: 12px;
        }
      `}</style>
    </>
  )
}
