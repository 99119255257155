import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import { useContext, useEffect, useState } from 'react'
import { useAmp } from 'next/amp'
import { Link } from '../../atoms/Link'
import { TF1InfoLogo } from '../../atoms/Icon/TF1InfoLogo'
import { NotificationsButton } from '../../atoms/NotificationsButton'
import { UserButton } from '../../atoms/UserButton'
import { MenuBurgerButton } from '../../atoms/MenuBurgerButton'
import { MenuNavigation } from '../../atoms/MenuNavigation'
import { TabNavigation } from '../../atoms/TabNavigation'
import { getTabMenu } from '../../../helpers/header'
import { useTMSHitForHeader } from '../../../hook/useTMSClickHit'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { DownloadBannerContext } from '../../../contexts/DownloadBanner'
import { mixins, theme } from '../../../styles'

export interface IHeaderHome {
  wordings?: {
    login?: string
  }
  navigation: BlockTypes['header-navigation']['data']['navigation']
}

export function HeaderHome({ navigation }: IHeaderHome): JSX.Element {
  const isAmp = useAmp()
  const { page } = useContext(ApiResponseContext)
  const { isDisplayed: isDownloadBannerDisplayed } = useContext(DownloadBannerContext)
  const isHome = page.url === '/'
  const { headerHit } = useTMSHitForHeader()
  const [isHeaderSmall, setIsHeaderSmall] = useState(!isHome)

  const hasTabNavigation = !!getTabMenu(navigation, page.url)

  function getIntersectionObserver(): IntersectionObserver {
    return new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const [entry] = entries

        setIsHeaderSmall(entry.intersectionRatio === 0)
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: [0, 1.0],
      },
    )
  }

  useEffect(() => {
    if (!isHome) return
    // If we are on the home page, we observe the body header as it is always there

    const bodyHeaderComponent = document.querySelector("[data-module='body-header']")
    if (bodyHeaderComponent) {
      const intersectionObserver = getIntersectionObserver()
      intersectionObserver.observe(bodyHeaderComponent)

      return () => {
        intersectionObserver.disconnect()
      }
    }
  }, [])

  return (
    <>
      <header
        role="banner"
        aria-label="En-tête principale"
        className={[
          'HeaderHome items-center',
          isHeaderSmall ? 'HeaderHome_Sticky' : '',
          !isHome ? 'HeaderHomeForYou' : '',
          hasTabNavigation ? 'HeaderHome--tabs' : '',
        ]
          .join(' ')
          .trim()}
      >
        <div className="HeaderHome__Logo flex justify-center items-center">
          <Link
            className="flex justify-center items-center"
            url="/"
            onClick={() => headerHit('logo')}
            title="Tf1 Info - Retour à l'accueil"
          >
            <TF1InfoLogo />
          </Link>
        </div>
        {navigation?.length ? (
          <MenuNavigation elementList={navigation} isSticky={isHeaderSmall} />
        ) : null}

        <div className="HeaderHome__Actions flex justify-end items-center">
          <UserButton />
          {!isAmp && <NotificationsButton />}
          <MenuBurgerButton />
        </div>
        <TabNavigation elementList={navigation} />
      </header>

      <style jsx>{`
        @media ${mixins.mediaQuery.tablet} {
          .HeaderHome {
            top: ${isDownloadBannerDisplayed && !isHeaderSmall ? '88px' : '0'};
          }
        }
      `}</style>
      <style jsx>{`
        @keyframes appearFromTop {
          from {
            transform: translateY(-100%);
          }
          to {
            transform: translateY(0);
          }
        }

        .HeaderHome {
          position: sticky;
          top: 0;
          z-index: 20;
          background-color: ${theme.cssVars.deepBlue};
          grid-template: 'actions' auto / 1fr;
        }

        .HeaderHome.HeaderHomeForYou {
          background-color: ${theme.cssVars.darkestBlue};
        }

        .HeaderHome:not(.HeaderHomeForYou) {
          display: grid;
        }

        .HeaderHome:not(.HeaderHomeForYou)::after,
        .HeaderHomeForYou:not(.HeaderHome--tabs)::after {
          content: ' ';
          position: absolute;
          bottom: -5px;
          left: 0;
          width: 100vw;
          height: 5px;
          z-index: 1;
          background-color: ${theme.cssVars.darkBlue};
        }

        .HeaderHome__Logo {
          grid-area: logo;
          position: absolute;
          top: 6px;
          left: 0;
          transform: translateX(calc(50vw - 50%));
        }

        .HeaderHome__Logo :global(svg) {
          width: 165px;
          height: 55px;
        }

        .HeaderHome :global(.MenuNavigation) {
          position: absolute;
          bottom: 0;
        }

        .HeaderHome :global(.TabNavigation) {
          grid-area: tabs;
        }

        .HeaderHome__Actions {
          grid-area: actions;
          width: 100vw;
          gap: 20px;
          padding: 16px;
        }

        .HeaderHome__Actions :global(.UserButton) {
          margin-right: auto;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .HeaderHome {
            position: relative;
            width: 100%;
            background: ${theme.cssVars.deepBlue};
            flex-wrap: no-wrap;
            padding: 16px 0;
            grid-template: 'navigation actions' 1fr / auto;
          }

          .HeaderHome.HeaderHome_Sticky,
          .HeaderHome.HeaderHomeForYou {
            display: grid;
            grid-template: 'logo navigation actions' auto;
            grid-template-columns: auto 1fr auto;
          }

          .HeaderHome:not(.HeaderHome_Sticky) :global(.MenuNavigationItem--highlight::after) {
            top: 38px; // Arbitrary value to make the highlight appear below the header
          }

          .HeaderHomeForYou::after {
            content: ' ';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100vw;
            height: 5px;
            z-index: 1;
            background-color: ${theme.cssVars.darkBlue};
          }

          .HeaderHome :global(.MenuNavigation) {
            grid-area: navigation;
            position: relative;
            width: 100%;
            padding: 0 0 0 30px;
            height: 32px;
          }

          .HeaderHome_Sticky :global(.MenuNavigation) {
            padding: 0;
          }

          .HeaderHome_Sticky :global(.MenuNavigation__List) {
            justify-content: center;
          }

          .HeaderHome__Actions {
            height: 32px;
            margin-top: 0;
            width: initial;
            padding: 0 32px 0 0;
          }

          .HeaderHome__Actions :global(.UserButton) {
            margin-right: initial;
          }

          .HeaderHome_Sticky {
            position: sticky;
            height: ${theme.layout.desktop.header.height}px;
            top: 0;
          }

          .HeaderHome:not(.HeaderHomeForYou).HeaderHome_Sticky {
            animation: appearFromTop 600ms ease-in;
          }

          .HeaderHome_Sticky:not(.HeaderHome--tabs)::after {
            content: ' ';
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 100vw;
            height: 5px;
            z-index: 1;
            background-color: ${theme.cssVars.darkBlue};
          }

          .HeaderHome_Sticky .HeaderHome__Logo {
            position: relative;
            top: initial;
            padding-left: 30px;
            transform: translateX(0);
          }

          :not(.HeaderHome_Sticky) .HeaderHome__Logo {
            top: ${theme.layout.desktop.header.height + 20}px;
          }

          .HeaderHome__Logo :global(a) {
            height: 32px;
          }

          .HeaderHome__Logo :global(svg) {
            width: 180px;
            height: 55px;
          }

          :not(.HeaderHome_Sticky) .HeaderHome__Logo :global(svg) {
            width: 229px;
            height: 70px;
          }
        }

        @media ${mixins.mediaQuery.maxWidth} {
          .HeaderHome_Sticky .HeaderHome__Logo {
            padding-left: ${`max(30px, calc(50vw - ${theme.layout.maxWidth / 2 + 30}px))`};
          }

          .HeaderHome:not(.HeaderHome_Sticky) :global(.MenuNavigation) {
            padding: ${`0 0 0 max(30px, calc(50vw - ${theme.layout.maxWidth / 2 + 30}px))`};
          }

          .HeaderHome__Actions {
            padding: ${`0 max(30px, calc(50vw - ${theme.layout.maxWidth / 2 + 30}px)) 0 0`};
          }
        }
      `}</style>
    </>
  )
}
