import { useEffect, useState } from 'react'
import { theme } from '../styles'
import { isServer } from '../helpers/client'

export function useHeaderHeight(): number {
  const [headerHeight, setHeaderHeight] = useState(0)

  function handleResize() {
    setHeaderHeight(document.querySelector('header[role="banner"]')?.clientHeight || 0)
  }
  useEffect(() => {
    if (isServer()) return

    handleResize()
    document.querySelector('header[role="banner"]')?.addEventListener('resize', handleResize)

    return () => {
      document.querySelector('header[role="banner"]')?.removeEventListener('resize', handleResize)
    }
  }, [])

  return headerHeight
}
